<template>
  <div>
    <CookiesPolicy v-if="!shouldShowCookiesPolicy"/>
    <AsideCart/>
    <MyOrders/>
    <RegionalizationModal/>
    <router-view/>
  </div>
</template>

<script>
import CookiesPolicy from "@/components/Policy/CookiesPolicy"
import AsideCart from "@/components/Cart/AsideCart"
import RegionalizationModal from "@/components/Regionalization/RegionalizationModal"
import MyOrders from "@/components/Cart/MyOrders.vue"
import { SESSION_STORAGE_HOME_ROUTE_INFO_KEY } from "@/constants/sessionStorage"

export default {
  name: "Container",
  components: {
    CookiesPolicy,
    AsideCart,
    MyOrders,
    RegionalizationModal,
  },
  computed: {
    shouldShowCookiesPolicy() {
      return this.$route.name === 'HomePageDarkWeb' || this.$route.name === 'HomePageStatic'
    }
  },

  methods: {
    saveHomePageRouteInfo() {
      const currentRouteValues = this.$router.currentRoute.value
      const {
        name,
        path,
        fullPath,
        href
      } = currentRouteValues

      sessionStorage.setItem(SESSION_STORAGE_HOME_ROUTE_INFO_KEY, JSON.stringify({
        name,
        path,
        fullPath,
        href,
      }))
    }
  },

  mounted() {
    this.saveHomePageRouteInfo()
  }
}
</script>
